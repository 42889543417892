<template>
  <CreditCardPayment />
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import CreditCardPayment from "@/components/payments/CreditCardPayment";

export default {
  name: "PaymentCreditCardDepositAndMonthlyPage",
  components: {
    CreditCardPayment,
    RFButton,
    RFLoader,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  async beforeMount() {

  },
  async mounted() {

  },
  beforeDestroy() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
